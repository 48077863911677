import React from "react";
import PromotionPage from "components/Promotion";
import Async from "components/Async/Async";
import { getLang } from "../state/reducers/langSession";

const Promotion = ({ ...props }) => {
  const filteredPromotions = props.promotions.filter(
    (item) =>
      new Date(item.attributes.display_start_date).toISOString() <=
        new Date().toISOString() &&
      new Date(item.attributes.display_end_date).toISOString() >=
        new Date().toISOString()
  );

  const sortedPromotions = filteredPromotions.sort(
    (a, b) => a.attributes.order - b.attributes.order
  );

  return (
    <Async
      onSuccess={() => (
        <>
          <PromotionPage langData={props.langData} currentLang={getLang()} data={sortedPromotions} />
        </>
      )}></Async>
  );
};

export default Promotion;
