import React from "react";
import { Link } from "gatsby";

export default function Promotion({ data, langData, currentLang }) {
  return (
    <>
      <div className="reward">
        <div className="reward__banner"></div>
        <div className="reward__content">
          <div className="container container--500 promotions--title">
            <h1 className="text--center">{langData.title_landing_pages}</h1>
            <p
              className="text--center"
              dangerouslySetInnerHTML={{
                __html: langData.description_landing_pages,
              }}></p>
          </div>

          <div className="container bar">
            <div className="mainbarpromo">
              <div className="rl__wrapper">
                {data !== null ? (
                  <>
                    {data.map((obj, key) => (
                      <Link
                        to={
                          currentLang === "en"
                            ? `/promotion/${obj.attributes.slug}`
                            : `/${currentLang}/promotion/${obj.attributes.slug}`
                        }
                        key={obj.attributes.slug}
                        className="rl promotion-single">
                        <div className="rl__content">
                          <div className="rl__img">
                            <img
                              src={obj.attributes.image.data.attributes.cdnURL}
                              className="full-width"
                            />
                          </div>
                          <div className="rl__inner">
                            <div className="rl__inner--top">
                              <div className="rl__title">
                                {obj.attributes.rewards_promotion_details
                                  .data[0]
                                  ? obj.attributes.rewards_promotion_details
                                      .data[0].attributes.name
                                  : ""}
                              </div>
                              <div
                                className="promotion-single--date"
                                dangerouslySetInnerHTML={{
                                  __html: obj.attributes
                                    .rewards_promotion_details.data[0]
                                    ? obj.attributes.rewards_promotion_details
                                        .data[0].attributes.intro
                                    : "",
                                }}></div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
