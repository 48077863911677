import React from "react";
import PropTypes from "prop-types";
import { Typography, Spin } from "antd";
import { IN_PROGRESS, FAILED, SUCCESS } from "state/constants/actions";
import "./async.less";

const Async = ({
  uiState,
  onSuccess,
  onFailure,
  onProgress,
  error,
  isLoading,
}) => {
  return (
    <>
      {(uiState === IN_PROGRESS || isLoading) && onProgress()}
      {(uiState === SUCCESS || !isLoading) && onSuccess()}
      {uiState === FAILED && error && onFailure(error)}
    </>
  );
};

Async.propTypes = {
  uiState: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
  error: PropTypes.object,
};

Async.defaultProps = {
  onProgress: () => (
    <div className="async_loader">
      <Spin size="large" />
    </div>
  ),
  onFailure: (error) => (
    <Typography.Text type="danger">
      {error?.message || "Something went wrong"}
    </Typography.Text>
  ),
};

export default Async;
